import type { VFC } from "react";
import { useState } from "react";

import { css } from "@emotion/react";

import SearchPlaceholder from "src/components/SearchPlaceholder";
import Page from "src/components/templates/Page";

import FilterGroup from "./components/FilterGroup";
import StatsSortableTable from "./StatsSortableTable";

const wrapperStyle = css`
  display: grid;
  grid-gap: 1rem;

  box-sizing: border-box;
  width: 100%;
  height: 100%;

  padding: 2rem;

  grid-template-rows: auto minmax(0, 1fr);
`;

const InspectorsStatsPage: VFC = () => {
  const [isSearched, setIsSearched] = useState(false);

  return (
    <Page>
      <div css={wrapperStyle}>
        <FilterGroup onSearch={() => setIsSearched(true)} />
        {isSearched ? <StatsSortableTable /> : <SearchPlaceholder />}
      </div>
    </Page>
  );
};

export default InspectorsStatsPage;
